.footer {
  padding: 1rem 1rem;
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 auto;
  justify-content: center;
}

.pipeSpacer {
  margin: 0 1rem;
}

.itemSpacer {
  margin-left: 0.5rem;
}

.horizontal_list {
  list-style: none;
  display: flex;
}

.horizontal_list li:last-child {
  margin-right: 0; /* Remove margin for the last item */
}
