.list {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}

.item {
  border: 1px solid #d5dbdb;
  padding: 1rem;
}

.item:not(:first-child) {
  margin-top: -1px;
}

.content {
  margin: 0 2.5rem;
}

.itemHeader {
  /* Override Polaris default to align the heading with the expand icon. */
  margin: -0.4rem 0 !important;
  cursor: pointer;
}

.tableBorders {
  border: 1px solid grey;
  border-collapse: collapse;
  text-align: center;
  border-spacing: 5px;
}
