.provider-button {
  font-size: 17px !important;
}

.idpHeader {
  width: 90%;
  padding-right: 8px;
}

.essoEmailInputBox {
  display: block;
  width: 78.5%;
}

.idpBox {
  border: 1px solid #d5dbdb;
  padding: 20px;
  position: relative;
  margin-bottom: 16px;
}

.idpBox_last {
  border: 1px solid #d5dbdb;
  padding: 20px;
  position: relative;
  margin-bottom: 0px;
}

.idpText {
  font-size: 18px !important;
  margin-left: 0rem;
  font-weight: bolder !important;
  margin-bottom: 0;
  line-height: 50px;
}
.icon {
  margin-right: 1rem;
}

.lwaBadge {
  padding-top: 5px;
}

.show_all {
  margin-top: 8px;
}

/* Obs. Polaris Overrides */
.providerDescriptionText {
  color: gray !important;
  font-size: 5px !important;
  font-weight: lighter !important;
  position: static;
  padding-left: 30px;
}

.idpDescription {
  color: grey;
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 0;
  padding-top: 0;
}

.siginInButton {
  right: 10px;
  padding-top: 10x;
  padding-bottom: 10px;
  padding-left: 8px;
  margin-top: 55px;
}

.siginIn {
  right: 10px;
  padding-top: 10x;
  padding-bottom: 10px;
  padding-left: 8px;
  margin-top: 20px;
}

.essoSiginInButton {
  position: absolute;
  right: 10px;
  padding-top: 5x;
  padding-bottom: 20px;
  padding-left: 8px;
}

.siginInButtonWithDescription {
  right: 10px;
  padding-top: 10x;
  padding-bottom: 10px;
  padding-left: 8px;
  margin-top: 55px;
}

.rowElements {
  display: flex;
  flex-direction: row;
  margin-bottom: 0px;
}

.colElements {
  display: flex;
  flex-direction: column;
}

/* Obs. Polaris Overrides */
.icon img {
  height: 50px !important;
  width: 25px !important;
  padding-left: 10px;
  padding-right: 30px;
  margin-top: -4px !important;
}

.bid {
  padding-left: 10px;
  padding-right: 15px;
  margin-top: 55px !important;
}

.button {
  margin-bottom: 0.9rem;
}

/* Obs. Polaris overrides */
.button button {
  width: 100% !important;
  padding: 1.42rem 2.2rem !important;
  border-radius: 6px !important;
}

.lwa {
  padding-right: 10px;
  margin-left: 10px !important;
}

.customBadge {
  justify-content: center;
  align-items: center;
  text-align: center;
}
