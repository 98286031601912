.container {
  width: 65rem;
  max-width: 90rem;
  margin-top: 40px;
}

.maxContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1040px;
  padding: 20px 5% 40px 5%;
}
