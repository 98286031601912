.heading {
  color: var(--grey-900, #16191f);
  /* Heading/Heading 1 */
  font-family: Amazon Ember;
  font-size: 50px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 16px;
}

.heading_div {
  margin-bottom: 16px;
}

.linkStyle {
  text-decoration: none;
}

.lastUsedLwaButton {
  margin-bottom: 10px;
}
